import React from "react"
import Navbar from "../components/GMTools/Navbar"
import Footer from "../components/GMTools/Footer"
import Layout from "../components/App/Layout"
import MainBanner from "../components/GMTools/MainBanner"
import FeaturedService from "../components/GMTools/FeaturedService"
import MarketAnalytics from "../components/GMTools/MarketAnalytics"
import MassActions from "../components/GMTools/MassActions"
import SnipeTools from "../components/GMTools/SnipeTools"
import Roadmap from "../components/GMTools/Roadmap"
import StartProject from "../components/GMTools/StartProject"

const Home = () => {
  return (
    <Layout>
      <Navbar />
      <MainBanner />
      <MarketAnalytics />
      <SnipeTools />
      <MassActions />
      <StartProject />
      <Footer />
    </Layout>
  )
}

export default Home